import React, { FC } from 'react';
import { withPrefix } from 'gatsby';
import { Router } from '@reach/router';
import { Route } from 'components';

import BaseCheckout from './index';
import OldPayments from '../oldPayments';

const Checkout: FC = () => (
  <Router basepath={withPrefix('/start/checkout-special')}>
    {/* {Route('/payments', OldPayments)} */}
    {Route('/:code', BaseCheckout)}
  </Router>
);

export default Checkout;
